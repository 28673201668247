import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { VerifyOTP } from "./verify";
import { reVerifyPhoneRequestOTP, signUpReverifyWithOtp } from "../store/session";
import { Store } from "../store/store.model";

const verifyPageState = (state: Store) => ({
  isVerifying: state.session.isVerifying,
});

const verifyPageActions = (dispatch) =>
  bindActionCreators(
    {
      signUpReverifyWithOtp,
      reVerifyPhoneRequestOTP,
    },
    dispatch
  );

const VerifyPage = connect(verifyPageState, verifyPageActions)(VerifyOTP);

export { VerifyPage };
