import { AUTH_LOGIN_PATH, AUTH_SIGN_UP_PATH } from "@src/appV2/Auth";
import { Redirect, Route, Switch } from "react-router-dom";

import { EmailVerify } from "../login/emailVerify";
import { VerifyLogin } from "../login/verifyLogin";
import { getAppV2Route } from "../routing/constant/appV2Routes";
import { WelcomeRouterPath } from "../routing/constant/welcomeRoute";

export function WelcomeRoute(): JSX.Element {
  return (
    <Switch>
      <Route exact path={WelcomeRouterPath.LANDING_PAGE}>
        <Redirect to={getAppV2Route(AUTH_SIGN_UP_PATH)} />
      </Route>

      <Route exact path={WelcomeRouterPath.LOGIN}>
        <Redirect to={getAppV2Route(AUTH_LOGIN_PATH)} />
      </Route>

      {/**
       * When using log in via email, the API/Firebase's email link points to
       * LOGIN_VERIFY_LINK. This is not part of /v2 auth refactoring.
       */}
      <Route exact path={WelcomeRouterPath.LOGIN_VERIFY_LINK} component={VerifyLogin} />

      {/* This route is used for auto-login links which are generated via HCF Admin App */}
      <Route exact path={WelcomeRouterPath.LOGIN_EMAIL_VERIFY} component={EmailVerify} />
    </Switch>
  );
}
