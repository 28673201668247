import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonLabel,
  IonPage,
  IonRow,
  IonSpinner,
  IonToolbar,
} from "@ionic/react";
import { OnboardingRouterPath } from "@src/app/routing/constant/onboardingRoute";
import { useToast } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { ActionType } from "@store/session";
import { closeSnackbar } from "notistack";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { USER_EVENTS } from "../../constants/userEvents";
import useQuery from "../../hooks/useQuery";
import { OtpInputs } from "../components/auth/otpInputs";
import { reVerifyPhoneRequestOTP, signUpReverifyWithOtp } from "../store/session";

const CODE_LENGTH = 6;
interface VerifyProps {
  isVerifying: boolean;
  signUpReverifyWithOtp: typeof signUpReverifyWithOtp;
  reVerifyPhoneRequestOTP: typeof reVerifyPhoneRequestOTP;
}

// This is used in onboarding flow for reverification
export function VerifyOTP(props: VerifyProps) {
  const { isVerifying, signUpReverifyWithOtp, reVerifyPhoneRequestOTP } = props;
  const history = useHistory();
  const [code, setCode] = useState<string>("");
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const { showSuccessToast, showErrorToast } = useToast();
  const dispatch = useDispatch();

  const query = useQuery();
  const phone = query.get("phoneNumber") ?? "";

  const verify = async (newCode?: string) => {
    if (isVerifying) {
      return;
    }
    if (newCode?.trim().length !== CODE_LENGTH) {
      return;
    }
    if (isButtonDisabled) {
      setButtonDisabled(false);
    }
    try {
      logEvent(USER_EVENTS.ENTERED_TWO_FACTOR_CODE);
      const isOTPVerified = await signUpReverifyWithOtp(newCode, phone);

      if (!isOTPVerified) {
        showErrorToast("Invalid OTP");
        return;
      }

      history.push(OnboardingRouterPath.ONBOARDING_EMAIL);
    } catch (error) {
      closeSnackbar();
      logEvent(USER_EVENTS.ONBOARDING_ERROR, {
        reason: "Error verifying phone at sign-up",
        phone,
      });
    } finally {
      dispatch({
        type: ActionType.SET_IS_VERIFYING_DONE,
      });
    }
  };

  const resendOTP = async () => {
    const verification = await reVerifyPhoneRequestOTP(phone);

    if (verification as unknown) {
      showSuccessToast("OTP re-sent successfully.");
    }
  };

  return (
    <IonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/welcome" mode="ios" color="dark" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <div className="content-layout signup-content verifyotp-content ">
          <div className="form-container">
            <IonRow>
              <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
                <div className="form-heading">
                  <h4>Enter your verification code</h4>
                  <p>We’ve just sent a verification code to {phone} </p>
                </div>
                <div>
                  <IonLabel className="form-label">Verification Code</IonLabel>
                  <OtpInputs verify={verify} code={code} setCode={setCode} />
                </div>
                <button
                  className="button-reset resend-otp-link"
                  data-testid="resend-otp-link"
                  onClick={resendOTP}
                >
                  Didn't get a code? Resend it now.
                </button>
              </IonCol>
            </IonRow>
          </div>

          <div className="signupform-footer footer-container">
            <IonButton
              expand="block"
              size="large"
              class="ion-margin-top ion-margin-bottom continue-button"
              onClick={() => verify(code)}
              disabled={isVerifying || isButtonDisabled}
              id="verify-button"
              data-testid="verify-button"
            >
              Continue
              {isVerifying && <IonSpinner slot="end" class="ion-margin-start" name="lines" />}
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
